import {
  addResponseMessage,
  addUserMessage,
  dropMessages,
  setQuickButtons,
  toggleWidget,
  Widget
} from 'react-chat-widget'

import '@src/assets/css/react-chat-widget.css'
import LoveChatIcon from '@src/assets/images/love-chat.png'
import React, {useEffect, useState} from 'react'
import {httpGet, httpPost, LocalStorageKey} from '@src/utils'
import {v4 as uuidv4} from 'uuid'
import * as C from '@src/commons'
import {USER_LIMIT} from "@shared/statuscode"
import {useHistory} from "react-router-dom"

const html2canvas = require('html2canvas');

interface Props {
  makeHashID: string
  backgroundImageUrl?: string
  fullScreen?: boolean
}

export const ChatWidget = (props: Props) => {
  const {fullScreen, backgroundImageUrl} = props

  // Save chat request ID
  var chatRequestID = window.localStorage.getItem(LocalStorageKey.CHAT_REQUEST_ID);
  if(!chatRequestID) {
    chatRequestID = uuidv4();
    window.localStorage.setItem(LocalStorageKey.CHAT_REQUEST_ID, chatRequestID);
  }
  
  const history = useHistory()
  const [requestId, setRequestId] = useState(chatRequestID);
  const [subtitle, setSubtitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [widgetHeight, setWidgetHeight] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState(backgroundImageUrl);

  useEffect(() => {
    dropMessages();
    setLoading(true);
    setQuickButtons([
      {label: 'Reset', value: 'reset'},
      {label: 'Download as image', value: 'download'},
      {label: 'How to chat', value: 'twitter'},
    ]);

    httpGet('api/chat/history?makeHashID=' + encodeURIComponent(props.makeHashID) + '&requestID=' + encodeURIComponent(requestId)).then((res) => {
      setLoading(false);

      if(res.histories && res.histories.length > 0) {
        res.histories.forEach((history: any) => {
          if(history.role == 'user')
            addUserMessage(history.content || '');
          else
            addResponseMessage(history.content || '');
        });
      } else {
        addResponseMessage('Hi there! ❤️');
      }
    });
  }, [props.makeHashID]);

  function handleQuickButtonClicked(val: string) {
    if(val == 'download')
      downloadImage();
    else if(val == 'twitter')
      window.open('https://twitter.com/pornsushi_ai');
    else if(val == 'reset') {
      if(window.confirm('Do you really want to delete all chat history?')) {
        dropMessages();
        
        chatRequestID = uuidv4();
        setRequestId(chatRequestID);
        window.localStorage.setItem(LocalStorageKey.CHAT_REQUEST_ID, chatRequestID);

        //
        httpPost('api/chat/historyReset?makeHashID=' + encodeURIComponent(props.makeHashID) + '&requestID=' + encodeURIComponent(requestId), {});
      }
    }
  }

  function hiddenClone(element: HTMLElement) {
    // Create clone of element
    var clone = element.cloneNode(true) as HTMLElement;
  
    // Position element relatively within the 
    // body but still out of the viewport
    var style = clone.style;
    style.position = 'absolute';
    style.top = window.innerHeight + 'px';
    style.left = '0';
    style.overflowY = 'visible !important';
    style.height = 'auto';
    style.maxHeight = 'none';
    style.backgroundSize = '100% auto';
    
    // Append clone to body and return the clone
    document.body.appendChild(clone);
    return clone;
  }

  function downloadImage() {
    var e = hiddenClone(document.getElementById('messages')!);
    html2canvas(e, {
      allowTaint: true,
      useCORS: true
    }).then((canvas: HTMLCanvasElement) => {
      document.body.removeChild(e);
      var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.
      var link = document.createElement('a');
      link.download = 'screenshot.png';
      link.href = image;
      link.click();
    });
  }

  const handleNewUserMessage = async (newMessage: any) => {
    if(!newMessage)
      return;
    if(loading)
      return;

    //
    setLoading(true);
    setSubtitle("She's thinking... ⏳");

    try {
      const token = window.localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);

      var response = await fetch(C.API_URL + 'api/chat/gpt', {
        method: 'POST',
        body: JSON.stringify({
          make_hash_id: props.makeHashID,
          request_id: requestId,
          input: newMessage,
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + token,
        }
      });


      const utf8Decoder = new TextDecoder("utf-8");
      const reader = response.body!.getReader();
      let { value: chunkBuf, done: readerDone } = await reader.read();
      var chunk: string = chunkBuf ? utf8Decoder.decode(chunkBuf) : "";
    
      const newline = /[\.\!]/gm;
      let startIndex = 0;

      if (response.status == 204) {
        alert(token ? USER_LIMIT.CHAT.MESSAGE.PRO : USER_LIMIT.CHAT.MESSAGE.UNSIGNED);
        history.push(token ? '/getpro' :'/signin');
        return;
      }
    
      var buf = ''
      while (true) {
        const result = newline.exec(chunk);
        if (!result) {
          if (readerDone) break;
          const remainder = chunk.substr(startIndex);
          ({ value: chunkBuf, done: readerDone } = await reader.read());
          chunk = remainder + (chunk ? utf8Decoder.decode(chunkBuf) : "");
          startIndex = newline.lastIndex = 0;
          continue;
        }
        var buf = chunk.substring(startIndex, result.index + 1);
        startIndex = newline.lastIndex;

        if(buf.trim())
          addResponseMessage(buf.trim());
      }
    
      if (startIndex < chunk.length) {
        // Last line didn't end in a newline char
        buf = chunk.substr(startIndex);

        if(buf.trim())
          addResponseMessage(buf.trim());
      }
    } finally {

    }

    //
    setLoading(false);
    setSubtitle("");
  };
  
  function resize(event: any) {
    const h = event.target.height;
    setWidgetHeight(h);
  }

  //
  useEffect(() => {
    if(window.visualViewport) {
      setWidgetHeight(window.visualViewport!.height);
      window.visualViewport!.addEventListener('resize', resize);
      return () => window.visualViewport!.removeEventListener('resize', resize);
    }
  }, []);

  // Scrolls to bottom
  useEffect(() => {
    let elements = document.getElementById('messages');
    if(elements)
      elements.scrollTop = elements.scrollHeight;
  }, [widgetHeight]);

  useEffect(() => {
    if (fullScreen) {
      toggleWidget()
      return () => {
        toggleWidget()
      }
    }
  }, [])
  return (
    <>
      <style dangerouslySetInnerHTML={{__html: `
      .rcw-messages-container { 
        background-image: url('${backgroundImage}');
        background-repeat: no-repeat;
        -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
           -moz-animation: fadein 2s; /* Firefox < 16 */
            -ms-animation: fadein 2s; /* Internet Explorer */
             -o-animation: fadein 2s; /* Opera < 12.1 */
                animation: fadein 2s; 
      }
      @keyframes fadein {
          from { opacity: 0; }
          to   { opacity: 1; }
      }
      /* Firefox < 16 */
      @-moz-keyframes fadein {
          from { opacity: 0; }
          to   { opacity: 1; }
      }
      /* Safari, Chrome and Opera > 12.1 */
      @-webkit-keyframes fadein {
          from { opacity: 0; }
          to   { opacity: 1; }
      }
      @media screen and (max-width:800px) {
        .rcw-widget-container {
          height: ${widgetHeight}px;
        }
      }
    `}} />
      <Widget
        showBadge={true}
        fullScreenMode={fullScreen}
        showCloseButton={!fullScreen}
        showTimeStamp={false}
        launcherOpenImg={LoveChatIcon}
        profileAvatar={LoveChatIcon}
        title=""
        subtitle={subtitle}
        handleNewUserMessage={handleNewUserMessage}
        senderPlaceHolder={(!loading ? 'Type a message...' : 'She is typing... ⏳')}
        handleQuickButtonClicked={handleQuickButtonClicked}
        />
    </>
  );  
}
