
import React from 'react'
import {Row, Col, Container, Button} from 'react-bootstrap'
import '@src/assets/css/sushi.scss'
import Card from '@src/components/Card'
import {Link} from 'react-router-dom'
import SeoulLayout from './seoul-layout'


const GetPro = () => {

   function visit() {
      const redirectURI = `https://${window.location.host}/getpro/patreon`;
      window.location.href = "https://www.patreon.com/oauth2/authorize"
         + "?response_type=code"
         + "&client_id=K5y6gJ4C0aEAGj4AI1GwxoddQ_xmTjeKN2rK5VRitHBYCM_dGpS_DuIy8TRTgkBO"
         + "&scope=" + encodeURIComponent(['identity.memberships', 'identity'].join(' '))
         + "&redirect_uri=" + encodeURIComponent(redirectURI);
   }

    return (
         <div>
            <SeoulLayout>
               <Row>
                  <Col sm={{span: 6, offset: 3}}>
                     <a 
                        href="https://patreon.com/pornsushi" 
                        className="d-flex text-center justify-content-center" 
                        style={{height: "90px", fontSize: "13px"}}
                        target="_blank"
                     >
                           <p className="text-white pro-button">Subscribe to Pro and enjoy various benefits! (and even more updates in the future!)</p>
                     </a>
                  </Col>
                  <Col sm={{span: 6, offset: 3}} style={{textAlign: 'center', color: 'white', fontSize: "18px"}}>
                        <p>⭐ Generate images faster!</p>
                        <p>⭐ Inpainting editor!</p>
                        <span style={{fontSize: "13px"}}>Change or keep specific parts of an image</span>
                        <img src="/images/pro_1.png" className='img-thumbnail'/>
                        <span style={{fontSize: "13px"}}>Use the same body and change the face</span>
                        <img src="/images/pro_2.png" className='img-thumbnail'/>
                        <span style={{fontSize: "13px"}}>Use the same face and change the body</span>
                        <img src="/images/pro_3.png" className='img-thumbnail'/>
                        <img src="/images/pro_4.png" className='img-thumbnail'/>
                        <img src="/images/pro_5.png" className='img-thumbnail'/>
                        <span style={{fontSize: "13px"}}>Add or remove clothing on any generation!</span>
                        <img src="/images/pro_6.png" className='img-thumbnail'/>
                        <img src="/images/pro_7.png" className='img-thumbnail'/>
                        <p>⭐ Uncrop mode!</p>
                        <span style={{fontSize: "13px"}}>Zoom out to reveal more!</span>
                        <img src="/images/pro_8.png" className='img-thumbnail'/>
                        <p>⭐ Upscale 4X mode!</p>
                        <span style={{fontSize: "13px"}}>Upscale an image by 4x to 2048x2048 resolution!</span>
                        <img src="/images/pro_10.png" className='img-thumbnail'/>
                        <p>⭐ No watermark!</p>
                        <p>⭐ Faster support!</p>
                        <p>⭐ More updates!</p>

                        <a href="https://patreon.com/pornsushi" className='d-flex justify-content-center'
                        target='_blank'
                        ><button className="pro-button">Subscribe via Patreon!</button></a>
                        <p>
                           <Link to="#" onClick={(e) => visit()}>If you have already subscribed, please click here.</Link>
                        </p>
                  </Col>
               </Row>
            </SeoulLayout>
         </div>
    )
}

export default GetPro