
import React, { useEffect } from 'react'
import {Row, Col, Container, Button, Form} from 'react-bootstrap'
import Card from '@src/components/Card'
import {Link, useHistory, useLocation} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ReducerType } from '@src/store'
import { IMake, IUser } from '@src/shared/types'
import { getAccessToken, httpGet, httpPost, setAccessToken } from '@src/utils'
import SeoulLayout from './seoul-layout'


const MeSaves = () => {

   const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
   const [makes, setMakes] = React.useState([]);
   const history = useHistory();

   
   useEffect(() => {

    //
    if(!getAccessToken()) {
     alert('You need to be signed.');
     history.push('/signin');
    }
    //
    httpGet('api/me/saves').then((res) => {
      if(res.status >= 0)
        setMakes(res.makes);
    });
   });

   function signout() {
    setAccessToken('');
    history.push('/');
   }

   function savedItems() {

   }

    return (
      <SeoulLayout>
        <div className="text-center">
            <Button variant="light" onClick={() => history.push('/me')} className="me-2">My generated items</Button>
            <Button variant="light" onClick={signout}>Sign out</Button>
        </div>
        <Row>
          {makes.map((x: IMake) => (
            <Col md={3} lg={2} xs={6} className="p-1" key={x.hash_id}>
              <Link to={"/make/" + x.hash_id}>
                <img src={x.image_url} width="100%"/>
              </Link>
            </Col>
          ))}
        </Row>
      </SeoulLayout>
    )
}

export default MeSaves