import React, {useEffect, useState} from 'react'
import {Row, Col, Container, Form, Button} from 'react-bootstrap'
import {Link, Redirect} from 'react-router-dom'
import {useHistory} from 'react-router-dom'
import {CodeResponse, CredentialResponse, TokenResponse, useGoogleLogin, useGoogleOneTapLogin} from '@react-oauth/google';
import {getAccessToken, httpGet, httpPost, setAccessToken} from '@src/utils';
import * as C from '@src/commons';
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { setMe } from '@src/store/auth';
import { ReducerType } from '@src/store';
import { IUser } from '@src/shared/types';
import SeoulLayout from './seoul-layout';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type SigninFormDataType = {
  email: string
  password: string
  recaptcha: string
}

const SignIn = () => {
  let history = useHistory()
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState<SigninFormDataType>({
    email: '',
    password: '',
    recaptcha: '',
  });
  let dispatch = useDispatch();
  const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
  const { executeRecaptcha }: any = useGoogleReCaptcha();

  //
  const googleLogin = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      signin({googleAccessToken: credentialResponse.access_token});
    },
    onError: () => {
      console.log('Login failed.');
    },
  });

  //
  useGoogleOneTapLogin({
    onSuccess: (credentialResponse: CredentialResponse) => {
      signin({googleCredential: credentialResponse.credential});
    },
    onError: () => {
      console.log('Login failed.');
    },
  });

  async function signin(params: any) {
    const recaptcha = await executeRecaptcha('signin');
    params.recaptcha = recaptcha;

    //
    const data = await httpPost('api/me/signin', params);
    if (data.status >= 0) {
      setAccessToken(data.token);

      //
      var resMe = await httpGet('api/me');
      if(resMe.status >= 0) {
        dispatch(setMe(resMe.user));
      }

      //
      history.push('/');
      
    } else {
      Swal.fire({
        html: 'Check your email and password',
        icon: 'warning',
        confirmButtonText: 'OK',
      }).then();
    }
  }

  function signinTwitter() {

  }

  //
  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) return;
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);
    await signin(formData);
    setLoading(false);
  };

  return (
    <SeoulLayout>
      <div className="sign-in-from" style={{margin: '0 auto', width: '400px'}}>
        <Form
          className="mt-4 mt-5"
          onSubmit={handleSubmit}
        >
          <Form.Group className="form-group">
            <Form.Control
              type="email"
              className="mb-0"
              placeholder="Email"
              required
              defaultValue={formData.email}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  email: e.target.value,
                });
              }}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Control
              type="password"
              className="mb-0"
              placeholder="Password"
              required
              defaultValue={formData.password}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  password: e.target.value,
                });
              }}
            />
          </Form.Group>
            
          <Button
            variant="primary"
            type="submit"
            className="w-100"
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Sign'}
          </Button>
          <div className="mt-4">
            <Button
              variant="success"
              type="button"
              className="w-100"
              onClick={() => googleLogin()}
            >
              Sign in with Google 
            </Button>
          </div>
          {/* <div className="mt-2">
            <Button
              variant="success"
              type="button"
              className="w-100"
              onClick={signinTwitter}
            >
              Twitter 로그인
            </Button>
          </div> */}
          <div className="sign-info">
            <span
              className="dark-color d-inline-block line-height-2">
              Don't have account?{' '}
              <Link to="/signup">Sign up</Link>
            </span>
            <span
              className="dark-color d-inline-block line-height-2">
              Forgot password?{' '}
              <Link to="/forgotPassword">Here</Link>
            </span>
          </div>
        </Form>
      </div>
    </SeoulLayout>
  )
}

export default SignIn

