import * as React from 'react'
import {useEffect} from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import {Button} from 'react-bootstrap'


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'black',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const tagImageUrls = [
  { name: "cumshot", url: "https://cdn.pornsushi.ai/makes/0aba0oxG.jpg"},
  { name: "ahegao", url: "https://cdn.pornsushi.ai/makes/EmMLL7b4.jpg"}
]

export interface TagModalProps {
  handleCloseHandler: () => void
  handleLinkHandler: () => void
  tagName: string
  isOpen: boolean
}

export const TagModal: React.FC<TagModalProps> = (
  {
    handleCloseHandler,
    handleLinkHandler,
    tagName,
    isOpen
  }) => {
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    handleCloseHandler()
    setOpen(false)
  }
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])
  const tagImageUrl = tagImageUrls.find((tag) => tag.name == tagName )?.url
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Card sx={{maxWidth: 345, backgroundColor: "black", color: "white"}}>
            <CardMedia
              component="img"
              image={tagImageUrl ?? "https://cdn.pornsushi.ai/makes/BmPvBoxd.jpg"}
              alt={tagName}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Cumshot
              </Typography>
              <Typography gutterBottom variant="body1" component="div">
                You need to subscribe PRO mode to use this tag
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="pinky" className="me-2" onClick={handleLinkHandler}>⭐ Get Pro</Button>
              <Button variant="secondary" className="me-2" onClick={handleClose}>Close</Button>
            </CardActions>
          </Card>
        </Box>
      </Modal>
    </div>
  )
}
