
export enum StatusCode {
    SUCCESS = 0,
    FAILED = -1,
    PLEASE_LOGIN = -2,
    BAD_REQUEST = -3,
    NOT_FOUND_ITEM = -4,
    INVALID_FORM = -5,
    NOT_FOUND_PAYMENT_METHOD = -6,
    FAILED_TO_PAY = -7,
    SUBSCRIPTION_EXPIRED = -8,
    NOT_AUTHORIZED = -9,
    NOT_FOUND_USER = -10,
    WRONG_PASSWORD = -11,
    NOT_ENOUGH_FAN_MONEY = -12,
    NOT_FOUND_MAP = -13,
    NOT_VALID_RECAPTCHA = -14,
    NOT_SUBSCRIBED_PRO = -15,
    UNSIGNED_USER_LIMIT = -16,
    NOT_PRO_USER_LIMIT = -17,

    //
    ALREADY_EXISTS_EMAIL = -1000,
    ALREADY_EXISTS_NAME = -1001,
}

export const USER_LIMIT = {
    CHAT: {
        MESSAGE: {
            UNSIGNED: "Do you want to talk more? Sign in and continue the sexy conversation with our crazy fantastic AI!",
            PRO: "Don't you need more exciting conversations? Switch to Pro version and enjoy unlimited conversations!",
        }
    },
    MAKE: {
        MESSAGE: {
            UNSIGNED: "You can create up to 3 times without logging in. You can do more generation by logging in.",
            PRO: "You have reached the limit for free generation! Switch to Pro version and enjoy unlimited generation!",
        }
    }
}
