import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

//router
import { BrowserRouter } from 'react-router-dom'

//store

import { Provider } from 'react-redux';
//reducer
import {store} from './store'

import * as C from '@src/commons';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Provider store={store}>
        <GoogleReCaptchaProvider reCaptchaKey={C.RECAPTCHA_SITE_KEY}>
          <App />
        </GoogleReCaptchaProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
