//////////////////////////////
// Commons
//////////////////////////////

export interface IApiResult {
    status: number;
    message: string;
}

export interface IUser extends IUserProfile {
    hash_id: string;
    is_pro: boolean;
}

export interface IUserProfile {
    name: string;
    intro?: string;
}

export enum MakeType {
    GENERATE = 1,
    UP_SCALE = 2,
    FIX_DETAILS = 3,
    INPAINT = 4,
    UNCROP = 5,
}

export interface IMake {
    hash_id: string;
    parent_hash_id?: string;
    image_url: string;
    status: number;
    tags: string;
    visible: number;
    identity?: string;
}

export interface ITagGroup {
    id: number
    name: string
    activated: boolean
    seq: number
    tags: ITag[]
}

export interface ITag {
    id: number
    tag_group_id: number
    name: string
    activated: boolean
    seq: number
    pro_users_only: boolean
}
