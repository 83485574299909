
export const FONT_FAMILY = 'Noto Sans';

export const IS_LOCAL = process.env.NODE_ENV == 'development'
export const API_URL = IS_LOCAL || true ? '/' : 'https://backend.pornsushi.ai/';
// export const WEBSOCKET_URL = IS_LOCAL ? `http://${window.location.hostname}:4000/` : 'https://backend.pornsushi.ai/';

//
export const GOOGLE_CLIENT_ID = '88656551122-2fjog1q8qlm08f79mq072qhap4pgfsq8.apps.googleusercontent.com';
export const SAFTY_MODE = false;

export const RECAPTCHA_SITE_KEY = '6LeXTDUlAAAAAB_InR1PJp7trZj9TRDWxRifIERD';

//
export const VERSION = 1;