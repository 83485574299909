import {ITag, ITagGroup} from "@shared/types"
import {Button} from "react-bootstrap"
import React from "react"

export const TagButtons = (tagGroup: ITagGroup, tags: string[], onClickHandler: (tag: string, proUsersOnly?: boolean) => void) => {
  return <>
    {tagGroup.tags.map((t: ITag) => (
      t.pro_users_only ?
        (<Button key={t.name} variant="outline-pinky"
                 style={{
                   color: 'white', backgroundColor:
                     (tags.indexOf(t.name) !== -1 ? 'bg-pinky' : '#212529')
                 }}
                 className={"rounded-pill mb-1 me-1 " + (tags.indexOf(t.name) !== -1 ? 'bg-pinky' : '')}
                 onClick={() => onClickHandler(t.name, true)}>
          ⭐ {t.name}
        </Button>) :
        (<Button key={t.name} variant="dark"
                 className={"rounded-pill mb-1 me-1 " + (tags.indexOf(t.name) !== -1 ? 'bg-success' : '')}
                 onClick={() => onClickHandler(t.name, false)}>
          {t.name}
        </Button>)
    ))}
  </>
}
