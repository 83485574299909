import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Row} from 'react-bootstrap'
import {Link, useHistory} from 'react-router-dom'
import {getMakeTags, httpGet} from '@src/utils'
import {IMake, ITagGroup} from '@src/shared/types'
import SeoulLayout from './seoul-layout'
import {TagButtons} from "@shared/blocks"

const SearchPage = () => {
  let history = useHistory()
  let [loading, setLoading] = useState(false);
  let [generatorID, setGeneratorID] = useState(1);
  let [generator, setGenerator] = useState<ITagGroup[]>([]);
  let [rand, setRand] = useState(0);

  //
  // function handleSelectGenerator(e: any) {
  //   setGeneratorID(parseInt(e.target.value));
  //   setGenerator(Generators.find(x => x.id == parseInt(e.target.value)));
  // }

  //
  let INITIAL_TAGS: string [] = [];
  let params = new URLSearchParams(window.location.search);
  if(params.get('tags')) {
    INITIAL_TAGS = JSON.parse(window.atob(params.get('tags') || ''));
  }

  //
  const [tags, setTags] = useState(INITIAL_TAGS);
  let [makes, setMakes] = useState<IMake []>([])

  //
  function toggleTag(tag: string) {
    if(tags.includes(tag)) {
      setTags(tags.filter(x => x != tag));
    } else {
      setTags([...tags, tag]);
    }
  }

  function fetch(reset: boolean = true, random: boolean = false, randomValue: number = -1) {
    let tagsEncoded = window.btoa(JSON.stringify(tags));
    let url = 'api/makes?tags=' + encodeURIComponent(tagsEncoded);
    if(random)
      url += '&random=' + (randomValue < 0 && rand || randomValue);

    if(!reset) {
      let lastID = makes.length > 0 && makes[makes.length - 1].hash_id || '';
      url += '&lastID=' + lastID;
    }

    //
    httpGet(url).then((res) => {
      if(reset)
        setMakes(res.makes);
      else
        setMakes(makes.concat(res.makes));
    });
  }

  function clearTags() {
    setTags([]);
  }

  function copyTags() {
    let tagsEncoded = window.btoa(JSON.stringify(tags));
    let url = window.location.protocol + '//' + window.location.host + '/search?tags=' + encodeURIComponent(tagsEncoded);

    window.navigator.clipboard.writeText(url).then(() => {
      alert("Copied to clipboard!");
    });
  }

  function random() {
    const r = Math.random();
    setRand(r);
    fetch(true, true, r);
  }

  useEffect(() => {
    fetch();
  }, [tags]);

  useEffect(() => {
    const storageTags = getMakeTags()
    if (!storageTags) {
      httpGet('api/tags')
        .then((res: any) => {
          setGenerator(res.tags)
        })
    }else {
      setGenerator(storageTags)
    }
  }, [])


  function handleScroll(e: any) {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (bottom) {
      fetch(false, false);
    }
  }

  //
  return (
    <div style={{height: '100%'}}>
      <SeoulLayout style={{height: '100%'}}>
        <Row className="search-container">

          <Col className="col" md={6} sm={12} xs={12}>
            <Form style={{width: '300px'}}>
              {/*<Form.Group className="form-group">*/}
              {/*    <Form.Label htmlFor="generator">Generator</Form.Label>*/}
              {/*    <select className="form-select" id="generator" onChange={handleSelectGenerator} value={generatorID}>*/}
              {/*      {Generators.map(x => <option value={x.id} key={x.id}>{x.name}</option>)}*/}
              {/*    </select>*/}
              {/*</Form.Group>*/}
              <Form.Group className="form-group">
                <Button variant="success" className="me-2" onClick={clearTags}>Clear Tags</Button>
                <Button variant="light" className="me-2" onClick={copyTags}>Copy Tags</Button>
                <Button variant="light" onClick={random}>Random</Button>
              </Form.Group>
            </Form>

            <Form>
              {generator?.map((x: ITagGroup) => (
                  <Form.Group className="mb-4" key={x.name}>
                    <p className="mb-0 fw-bold">{x.name}</p>
                    {TagButtons(x, tags, toggleTag)}
                  </Form.Group>
                ))}

            </Form>
          </Col>

          <Col className="col mt-3" md={6} sm={12} xs={12} onScroll={handleScroll}>
            <Row className="p-2">
              {makes.map(x => (
                <Col md={4} lg={3} xs={6} className="p-2" key={x.hash_id}>
                  <Link to={"/make/" + x.hash_id} target="_blank">
                    <img src={x.image_url} width="100%" style={{minHeight: '100px'}}/>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </SeoulLayout>
    </div>
  )
}

export default SearchPage
