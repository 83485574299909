import axios, {AxiosError, HttpStatusCode} from 'axios';
import { OverlayTrigger, OverlayTriggerProps } from 'react-bootstrap';
import * as C from './commons';
import { StatusCode } from '@shared/statuscode';
import { IS_MOBILE } from './device';
import {ITagGroup} from "@shared/types"

export enum LocalStorageKey {
    MAKE_TAGS = 'makeTags',
    ACCESS_TOKEN = 'accessToken',
    REFERRAL = 'referral',
    CHAT_REQUEST_ID = 'chatRequestID',
}

export function setAccessToken(token: string | null) {
    if (!token) {
        window.localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);
    } else {
        window.localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, token);
    }
}

export function setMakeTags(tags?: ITagGroup[]) {
    if (!tags) {
        window.localStorage.removeItem(LocalStorageKey.MAKE_TAGS);
    } else {
        window.localStorage.setItem(LocalStorageKey.MAKE_TAGS, JSON.stringify(tags));
    }
}

export function getMakeTags() {
    const tags = window.localStorage.getItem(LocalStorageKey.MAKE_TAGS);
    return tags ? JSON.parse(tags) as ITagGroup[] : [];
}


export function getAccessToken(): string | null {
    return window.localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
}

export function setReferral(userHashID: string) {
    if (!userHashID) {
        window.localStorage.removeItem(LocalStorageKey.REFERRAL);
    } else {
        window.localStorage.setItem(LocalStorageKey.REFERRAL, userHashID);
    }
}

export async function handleResult(res: any) {
    if(res.status == StatusCode.PLEASE_LOGIN) {
        window.location.href = '/signin';
    } else if(res.status == StatusCode.NOT_SUBSCRIBED_PRO) {
        window.location.href = '/getpro';
    } else if (res.status == StatusCode.UNSIGNED_USER_LIMIT) {
        alert(res.message);
        window.location.href = '/signin';
    } else if (res.status == StatusCode.NOT_PRO_USER_LIMIT) {
        alert(res.message);
        window.location.href = '/getpro';
    } else if(res.message) {
        alert(res.message);
    } else if(res.status < 0) {
        alert('Error code: ' + res.status);
    }

    return res;
}

export async function httpGet(url: string) {
    try {
        const token = window.localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
        const res = await axios.get(C.API_URL + url, {
            responseType: 'json',
            headers: {
                Authorization: 'Basic ' + token,
            }
        });

        return handleResult(res.data);
    } catch(e) {
        console.error(e);
        return null;
    }
}

export async function httpPost(url: string, data: any, headers: any = {}, responseType = 'json') {
    try {
        const token = window.localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
        const config : any = {
            responseType: responseType,
            headers: {
                ...headers,
                Authorization: 'Basic ' + token,
            },
        };
        const res = await axios.post(C.API_URL + url, data, config);

        if(responseType == 'json')
            return handleResult(res.data);
        
        return res.data;
    } catch(e) {
        console.error(e);
        if (e instanceof AxiosError) {
            return e.response?.data;
        }
        return null;
    }
}

export async function httpPostUpload(url: string, data: FormData) {
    try {
        const token = window.localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
        const res = await axios.post(C.API_URL + url, data, {
            responseType: 'json',
            headers: {
                Authorization: 'Basic ' + token,
                'Content-Type': 'multipart/form-data',
            }
        });

        return handleResult(res.data);
    } catch(e) {
        console.error(e);
        if (e instanceof AxiosError) {
            return e.response?.data;
        }
        return null;
    }
}

const RESERVED_URLS = ['feeds', 'notifications', 'chats', 'profile', 'auth'];

export function isGamePageURL(url: string) {
    return !RESERVED_URLS.find(x => url.startsWith('/' + x));
}

export function getUserNameFromURL(url: string): string {
    const userName = url.split('/')[1];
    if(!userName) 
        return '';

    if(RESERVED_URLS.indexOf(userName) !== -1)
        return '';

    return userName;
}

export function OverlayTrigger2(data: OverlayTriggerProps & {className: string}): JSX.Element {
    return OverlayTrigger(data);
}

export function numberWithCommas(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getResolution(): number {
    return window.devicePixelRatio;
}

export function getTextResolution(): number {
    return window.devicePixelRatio;
}

export function randomBetween(start: number, end: number): number {
    if(start >= end)
        return start;

    return Math.floor((end - start + 1) * Math.random());
}

export function pickItem<T>(items: Array<T>): T {
    var i = Math.floor(Math.random() * items.length);
    return items[i];
}

export function inputPriceFormat(str: string) {
    const comma = (str: string) => {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    };
    const uncomma = (str: string) => {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    };
    return comma(uncomma(str));
}

export function isValidCategory(x: string) {
    return x != 'id' && x != 'name' && !x.startsWith('#');
}

export function isJSON(x: string) {
    try {
        JSON.parse(x);
    } catch (e) {
        return false;
    }

    return true;
}
