import React, {useEffect, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import {getMakeTags, httpGet, httpPost} from '@src/utils'
import SeoulLayout from './seoul-layout'
import {ITagGroup, IUser} from "@shared/types"
import {TagButtons} from "@shared/blocks"
import {useSelector} from "react-redux"
import {ReducerType} from "@src/store"

const TagsPage = () => {
  const history = useHistory()
  const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
  const [loading, setLoading] = useState(false);
  const [generatorID, setGeneratorID] = useState(1);
  const [generator, setGenerator] = useState<ITagGroup[]>([]);
  // function handleSelectGenerator(e: any) {
  //   setGeneratorID(parseInt(e.target.value));
  //   setGenerator(Generators.find(x => x.id == parseInt(e.target.value)));
  // }
  const [tags, setTags] = useState([] as string[]);

  useEffect(() => {
    const storageTags = getMakeTags()
    if (!storageTags) {
      httpGet('api/tags')
        .then((res: any) => {
          setGenerator(res.tags)
        })
    }else {
      setGenerator(storageTags)
    }
  }, [])

  //
  function toggleTag(tag: string) {
    if(tags.includes(tag)) {
      setTags(tags.filter(x => x != tag));
    } else {
      setTags([...tags, tag]);
    }
  }

  //
  function generate() {
    setLoading(true);
    httpPost('api/makes/generate', {
      generator_id: generatorID,
      tags: tags,
      visible: true,
      parent_id: 0,
    }).then((res) => {
      setLoading(false);

      //
      if(res.status >= 0) {
        history.push('/make/' + res.make.hash_id);
      }
    });
  }

  return (
    <div>
      <SeoulLayout>
        <div>
          <Form style={{width: '200px'}}>
            {/*<Form.Group className="form-group">*/}
            {/*    <Form.Label htmlFor="generator">Generator</Form.Label>*/}
            {/*    <select className="form-select" id="generator" onChange={handleSelectGenerator} value={generatorID}>*/}
            {/*      {Generators.map(x => <option value={x.id}>{x.name}</option>)}*/}
            {/*    </select>*/}
            {/*</Form.Group>*/}
            <Form.Group className="form-group">
              <Button variant="success" className="me-2">Clear Tags</Button>
              <Button variant="light">Copy Tags</Button>
            </Form.Group>
          </Form>

          <Form>
            {generator?.map((x: ITagGroup) => (
                <Form.Group className="mb-4">
                  <p className="mb-0 fw-bold">{x.name}</p>
                  {TagButtons(x, tags, toggleTag)}
                </Form.Group>
              ))}

          </Form>
        </div>
      </SeoulLayout>

      <div style={{position: 'absolute', width: '100%', bottom: '10px', textAlign: 'center'}}>
        <Button variant="success" style={{width: '200px'}} disabled={loading} onClick={() => generate()}>Generate</Button>
      </div>
    </div>
  )
}

export default TagsPage
