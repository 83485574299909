//router
import { MainRouter } from "./router"

//scss
import "./assets/scss/socialv.scss"
import "./assets/scss/customizer.scss"


// Redux Selector / Action
import { useDispatch } from 'react-redux';

// import state selectors
import { setSetting } from './store/setting/actions'
import {getAccessToken, httpGet, setAccessToken, setMakeTags} from "./utils"
import { setMe } from "./store/auth";
import { IApiResult } from "@shared/types";
import {useEffect, useState} from "react";

// Time ago
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import ko from 'javascript-time-ago/locale/ko.json'

// GA
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";

//
TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ko)
TimeAgo.setDefaultLocale(navigator.language)

//
ReactGA.initialize('G-1PJ76HBGBW')

//
function App() {
  const dispatch = useDispatch()
  const location = useLocation()
  dispatch(setSetting(undefined))

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: window.document.title });
  }, [location]);

  useEffect(() => {
    httpGet('api/tags')
      .then((res: any) => {
        setMakeTags(undefined)
        setMakeTags(res.tags)
      })
  }, [])

  useEffect(() => {
    if(!getAccessToken())
      return;

    //
    httpGet('api/me').then((res: any) => {
      if(res.status >= 0) {
        dispatch(setMe(res.user));
      } else {
        dispatch(setMe(undefined));
        setAccessToken(null);
        window.location.hostname = '/';
      }
    });
  }, []);

  document.body.style.backgroundColor = 'black';

  return (
    <div className="App">
      <MainRouter/>
    </div>
  );
}

export default App;
