import React, {useEffect, useRef, useState} from 'react'
import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import {Link, useHistory, useLocation, useParams} from 'react-router-dom'
import {httpGet, httpPost} from '@src/utils'
import {useSelector} from 'react-redux'
import {ReducerType} from '@src/store'
import {IUser, MakeType} from '@src/shared/types'
import SeoulLayout from './seoul-layout'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import TagSelector from './tag-selector'
import {ChatWidget} from './chat-widget'

enum Mode {
  NONE = 0,
  INPAINT,
  UNCROP,
}

enum InpaintMode {
  CHANGE_SELECTED = 0,
  KEEP_SELECTED = 1,
  // MORPH_SELECTED = 2,
  // FIX_SELECTED = 3,
  COUNT = 2,
}

const MakeDetails = () => {
  let history = useHistory()
  let location = useLocation()
  const [make, setMake] = useState(null as any);
  const [children, setChildren] = useState([] as any[]);
  const [liked, setLiked] = useState(false);
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(Mode.NONE);
  const image = useRef(null as HTMLImageElement | null);
  const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);
  const { executeRecaptcha }: any = useGoogleReCaptcha();

  //
  const brushSizeRef = useRef(10);
  const [brushMode, setBrushMode] = useState(true);
  const [inpaintMode, setInpaintMode] = useState(InpaintMode.CHANGE_SELECTED);

  //
  const [scale, setScale] = useState(0);
  const backCanvasRef = useRef(null as HTMLCanvasElement | null);
  const maskCanvasRef = useRef(null as HTMLCanvasElement | null);
  const backCtxRef = useRef(null as CanvasRenderingContext2D | null);
  const maskCtxRef = useRef(null as CanvasRenderingContext2D | null);
  const params: any = useParams();
  const [renderCounter, setRenderCounter] = useState(0);

  //
  const [inpaintTags, setInpaintTags] = useState([] as string[]);

  useEffect(() => {
    setMake(null);
    fetch();
  }, [params.id]);


  function fetch() {
    httpGet('api/makes/details?hashID=' + params.id).then((res) => {
      if(res.status < 0)
        return history.goBack();

      //
      setMake(res.make);
      setChildren(res.children);
      setLiked(res.isLiked);
      setSaved(res.isSaved);

      // If loading, retry
      if(res.make.status <= 1) {
        setTimeout(() => fetch(), 3000);
      }
    }).catch(() => {
      setTimeout(() => fetch(), 3000);
    });
  }
  
  function save() {
    setLoading(true);

    //
    setSaved(!saved);
    httpPost('api/me/saves', {
      make_hash_id: make.hash_id,
    }).then((res) => {
      setLoading(false);
    });
  }

  async function sendGenerate(type: MakeType, params: any = {}) {
    setLoading(true);
    setMode(Mode.NONE);
    
    const recaptcha = await executeRecaptcha('make');
    httpPost('api/makes/generate', {
      parent_hash_id: make.hash_id,
      type: type,
      visible: make.visible,
      recaptcha: recaptcha,
      ...params
    }).then((res) => {
      setLoading(false);

      //
      if(res.status >= 0) {
        history.push('/make/' + res.make.hash_id);
      }
    });
  }

  function upscale() {
    if(!me) {
      alert('Please login and subscribe to PRO mode!');
      return history.push('/signin');
    }

    if(!me.is_pro) {
      alert('This feature is for PRO mode only. Please subscribe to PRO mode!');
      return history.push('/getpro');
    }

    sendGenerate(MakeType.UP_SCALE);
  }

  function fixDetails() {
    sendGenerate(MakeType.FIX_DETAILS);
  }

  function useTags() {
    var tagsEncoded = window.btoa(JSON.stringify(make.tags));
    history.push('/make?tags=' + encodeURIComponent(tagsEncoded));
  }

  function report() {
    setLoading(true);
    httpPost('api/me/report', {
      make_hash_id: make.hash_id,
    }).then((res) => {
      setLoading(false);

      //
      if(res.status >= 0)
        alert('Reported this photo. Sorry for inconvenience.');
    });
  }

  useEffect(() => {
    //
    setScale(1);
    setRenderCounter(Math.random());
  }, [mode]);
  
  //
  useEffect(() => {
    if (!backCanvasRef.current || !make) return;
    const ctx = backCanvasRef.current.getContext("2d");
    backCtxRef.current = ctx;

    //
    const img = new Image();
    img.src = make.image_url;
    img.onload = function() {

      //
      image.current = img;
      setRenderCounter(Math.random());
    };

  }, [make, backCanvasRef.current]);

  //
  useEffect(() => {
    if (!maskCanvasRef.current) return;
    maskCanvasRef.current.addEventListener('touchstart', (e) => mousedown(maskCanvasRef.current!, e), false);
    maskCanvasRef.current.addEventListener('touchmove', (e) => mousemove(maskCanvasRef.current!, e), false);
    maskCanvasRef.current.addEventListener('touchend', (e) => mouseout(maskCanvasRef.current!, e), false);
  }, [maskCanvasRef.current]);

  //
  useEffect(() => {
    if (!maskCanvasRef.current || !make) return;
    const ctx = maskCanvasRef.current.getContext("2d");
    maskCtxRef.current = ctx;
    setRenderCounter(Math.random());
  }, [make, maskCanvasRef.current]);

  //
  var draw = false;
  var lastX = 0;
  var lastY = 0;

  //
  function mousedown(canvas: HTMLCanvasElement, e: any) {
    e.preventDefault();
    if(mode != Mode.INPAINT) return;
    const ctx = maskCtxRef.current;
    if(!ctx) return;

    //
    var currX = 0, currY = 0;
    if(!!e.targetTouches)
    {
        let r = canvas.getBoundingClientRect();
        currX = e.touches[0].clientX - r.left;
        currY = e.touches[0].clientY - r.top;
    }
    else
    {
        currX = e.offsetX;
        currY = e.offsetY;
    }
    
    //
    if(brushMode) {
      ctx.globalCompositeOperation = "source-over";
      ctx.fillStyle = 'rgba(255, 255, 255, 1)';
    } else {
      ctx.globalCompositeOperation = "destination-out";
      ctx.strokeStyle = 'rgba(0,0,0,1)';
    }

    //
    draw = true;
    lastX = currX;
    lastY = currY;
  }

  //
  function mousemove(canvas: HTMLCanvasElement, e: any) {
    e.preventDefault();
    if(mode != Mode.INPAINT) return;
    const ctx = maskCtxRef.current;
    if(!ctx) return;

    //
    var currX = 0, currY = 0;
    if(!!e.targetTouches)
    {
        let r = canvas.getBoundingClientRect();
        currX = e.touches[0].clientX - r.left;
        currY = e.touches[0].clientY - r.top;
    }
    else
    {
        currX = e.offsetX;
        currY = e.offsetY;
    }

    if(draw) {

      ctx.beginPath();
      if(brushMode) {
        for(var i=0; i<10; i++) {
          let x = lastX + (currX - lastX) / 10 * i;
          let y = lastY + (currY - lastY) / 10 * i;
          ctx.arc(x, y, brushSizeRef.current/2,0,Math.PI*2,false);
        }
        ctx.fill();
      } else {
        ctx.arc(lastX, lastY, brushSizeRef.current/2,0,Math.PI*2,false);
        ctx.fill();
      }

      lastX = currX;
      lastY = currY;
    }
  }

  //
  function mouseout(canvas: HTMLCanvasElement, e: any) {
    e.preventDefault();
    if(mode != Mode.INPAINT) return;
    
    //
    draw = false;
    const ctx = maskCtxRef.current;
    ctx!.closePath();
  }

  function clearInpaint() {
    const ctx = maskCtxRef.current;
    const w = ctx!.canvas.width;
    const h = ctx!.canvas.height;
    ctx!.clearRect(0, 0, w, h);
  }

  function inpaint() {
    if(!me) {
      alert('Please login and subscribe to PRO mode!');
      return history.push('/signin');
    }

    if(!me.is_pro) {
      alert('This feature is for PRO mode only. Please subscribe to PRO mode!');
      return history.push('/getpro');
    }

    var mask = maskCanvasRef.current!.toDataURL('image/png');
    sendGenerate(MakeType.INPAINT, {
      inpaint_mode: inpaintMode,
      mask: mask.substring(mask.indexOf(',')+1).toString(),
      inpaint_tags: inpaintTags,
    });
  }

  useEffect(() => {
    render();
  }, [scale]);

  function uncrop() {
    if(!me) {
      alert('Please login and subscribe to PRO mode!');
      return history.push('/signin');
    }

    if(!me.is_pro) {
      alert('This feature is for PRO mode only. Please subscribe to PRO mode!');
      return history.push('/getpro');
    }

    //
    sendGenerate(MakeType.UNCROP, {
      scale: 1 / scale,
    });
  }


  function render() {
    const ctx = backCtxRef.current;
    if(!ctx || !image.current) return;

    //
    const w = ctx!.canvas.width;
    const h = ctx!.canvas.height;
    ctx!.clearRect(0, 0, w, h);
    ctx!.drawImage(image.current, (w - w / scale) / 2, (h - h / scale) / 2, w / scale, h / scale);
  }

  React.useEffect(() => {
    if(!backCanvasRef.current || !maskCanvasRef.current || !image.current) return;
    var parent = backCanvasRef.current?.parentElement;

    //
    var ratio = image.current!.width / image.current!.height;
    var h = parent!.clientWidth / ratio;

    parent!.style.height = h + 'px';
    backCanvasRef.current!.width = parent!.clientWidth;
    backCanvasRef.current!.height = h;

    //
    maskCanvasRef.current!.width = parent!.clientWidth;
    maskCanvasRef.current!.height = h;

    //
    clearInpaint();
    render();
  }, [renderCounter]);

  var windowWidth = window.innerWidth;
  var windowHeight = window.innerHeight;

  function resize() {
    if(windowWidth == window.innerWidth && windowHeight == window.innerHeight) return;
    windowWidth = window.innerWidth;
    windowHeight = window.innerHeight;

    //
    setRenderCounter(Math.random());
  }

  //
  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  function searchTag(tag: string): void {
    var tagsEncoded = window.btoa(JSON.stringify([tag]));
    history.push('/search?tags=' + encodeURIComponent(tagsEncoded));
  }

  function showIdentity() {
    setLoading(true);
    httpPost('api/makes/identity?hashID=' + encodeURIComponent(make.hash_id), {}).then((res) => {
      setLoading(false);

      if(res.status >= 0) {
        setMake({
          ...make,
          identity: res.identity,
        });
      }
    });
  }
  
  return (
    <SeoulLayout>
      {make && make.status == 2 && <ChatWidget makeHashID={params.id} backgroundImageUrl={make?.image_url}/>
      }
      {make && make.status == 2 && 
        (mode == Mode.NONE &&
          <div style={{position: 'relative', margin: '0 auto', width: '512px', maxWidth: '100%', maxHeight: '768px'}}>
            <img src={make.image_url} style={{display: 'block', width: '100%'}}/>
          </div>
          || 
            <div style={{position: 'relative', margin: '0 auto', width: '512px', maxWidth: '100%', maxHeight: '768px'}}>
              <canvas ref={backCanvasRef} style={{position: 'absolute', display: 'block', width: '100%', maxHeight: '100%', zIndex: '0', backgroundColor: 'transparent'}}>
              </canvas>
              <canvas ref={maskCanvasRef} style={{position: 'absolute', display: 'block', width: '100%', maxHeight: '100%', zIndex: '1', backgroundColor: 'transparent', opacity: 0.8}}
              onMouseDown={(e) => mousedown(e.target as HTMLCanvasElement, e.nativeEvent)}
              onMouseMove={(e) => mousemove(e.target as HTMLCanvasElement, e.nativeEvent)}
              onMouseOut={(e) => mouseout(e.target as HTMLCanvasElement, e.nativeEvent)}
              onMouseUp={(e) => mouseout(e.target as HTMLCanvasElement, e.nativeEvent)}
              >
              </canvas>
            </div>
        )
      }
      {make && make.status == 2 &&
        <Col sm={{offset: 2, span: 8}}>

          <div className="text-center">
            {/* <img src={make.image_url} style={{maxWidth: '512px'}} /> */}

            {mode == Mode.NONE && (!me || !me.is_pro) &&
            <p className="mt-2 text-center">
              <Link to="/getpro">
                If you subscribe ⭐ Pro, you can remove "pornsushi.ai" watermark.
              </Link>
            </p>
            }

            {mode == Mode.NONE &&
            <p className="mt-2 text-center">
              <Button variant="danger" className="me-2 mb-2" disabled={loading} onClick={() => setMode(Mode.INPAINT)}>⭐ Inpaint</Button>
              <Button variant="light" className="me-2 mb-2" disabled={loading} onClick={() => setMode(Mode.UNCROP)}>⭐ Uncrop</Button>
              <Button variant="warning" className="me-2 mb-2" disabled={loading} onClick={upscale}>⭐ 4x High quality</Button>
              <Button variant="dark" className="me-2 mb-2" disabled={loading} onClick={fixDetails}>Fix Details</Button>
              <Button variant="success" className="me-2 mb-2" disabled={loading} onClick={save}>{saved ? '❤️ Saved' : '♡ Save'}</Button>
              <Button variant="info" className="me-2 mb-2" disabled={loading} onClick={useTags}>Use Tags</Button>
              <Button variant="secondary" className="me-2 mb-2" disabled={loading} onClick={report}>Report</Button>
            </p>
            }

            {mode == Mode.INPAINT &&
            <div className="text-center">
              <Form.Group className="form-group m-auto" style={{maxWidth: '256px'}}>
                    <Form.Label>Brush size</Form.Label>
                    <Form.Range min={10} max={200} defaultValue={brushSizeRef.current} onChange={(e) => brushSizeRef.current = e.target.valueAsNumber}/>
                </Form.Group>
              <p className="mt-2">
                <Button variant="success" className="me-2 mb-2" disabled={loading} onClick={() => setInpaintMode((inpaintMode + 1) % InpaintMode.COUNT)}>Mode: { inpaintMode == 0 ? 'Change selecteed' : 'Fix selected' }</Button>
                <Button variant="success" className="me-2 mb-2" disabled={loading} onClick={() => setBrushMode(!brushMode)}>Brush: {brushMode && 'paint' || 'erase'}</Button>
                <Button variant="success" className="me-2 mb-2" disabled={loading} onClick={clearInpaint}>Clear</Button>
                <Button variant="danger" className="me-2 mb-2" disabled={loading} onClick={inpaint}>Generate</Button>
                <Button variant="light" className="me-2 mb-2" disabled={loading} onClick={() => setMode(Mode.NONE)}>Cancel</Button>
              </p>
            </div>
            }

            {mode == Mode.UNCROP &&
            <div className="text-center">
              <Form.Group className="form-group m-auto" style={{maxWidth: '256px'}}>
                    <Form.Label>Uncrop size</Form.Label>
                    <Form.Range min={1} max={2} step={0.1} defaultValue={scale} onChange={(e) => setScale(e.target.valueAsNumber)}/>
                </Form.Group>
              <p className="mt-2">
                <Button variant="danger" className="me-2 mb-2" disabled={loading} onClick={uncrop}>Generate</Button>
                <Button variant="light" className="me-2 mb-2" disabled={loading} onClick={() => setMode(Mode.NONE)}>Cancel</Button>
              </p>
            </div>
            }
          </div>

          {mode == Mode.INPAINT && 
            <TagSelector onChange={(t) => setInpaintTags(t)}/>
          }

          {mode == Mode.NONE &&
          <div>
            {/* <p className="text-left">
              Generator: ~~~
            </p> */}
            <p>
              {make.tags.map((x: string) => 
                <Button variant="dark" className={"rounded-pill mb-1 me-1 btn-sm"} key={x} onClick={() => searchTag(x)}>
                    {x}
                </Button>
              )}
            </p>

            {!make.identity && <Card style={{maxWidth: '512px'}} className="m-auto mb-2">
              <Card.Body style={{color: 'black', whiteSpace: 'pre-line', wordWrap: 'break-word', textAlign: 'center'}}>
                <Button variant="primary" onClick={() => showIdentity()} disabled={loading}>
                    Show identity
                </Button>
              </Card.Body>
            </Card>}

            {make.identity && <Card style={{maxWidth: '512px'}} className="m-auto mb-2">
              <Card.Body style={{color: 'black', whiteSpace: 'pre-line', wordWrap: 'break-word'}}>
                {make.identity.trim()}
              </Card.Body>
            </Card>
            }

            {make.parent_hash_id && 
              <p className="text-center">
                <Link to={"/make/" + make.parent_hash_id}>
                  View original
                </Link>
              </p>
            }

            {children && children.length > 0 && 
              <p className="text-center">
                  Edits
              </p>
            }

            <Row>
            {children && children.map(x => (
              <Col md={3} className="p-1" key={x.hash_id}>
                <Link to={"/make/" + x.hash_id}>
                  <img src={x.image_url} width="100%"/>
                </Link>
              </Col>
            ))}
            </Row>
          </div>
          }
        </Col>
      ||
      <>
        <div className="text-center">
            {!make || make.status <= 1 && <p>
              Loading...<br/>
              {me && me.is_pro && <>
                It may take about a minute.<br/>
                ⭐ You are PRO! Thanks a lot.<br/>
              </> || <>
                It may take about 5 minutes.<br/>
                Switch to Pro mode for faster creation.<br/>
              </>}
            </p>}
            {make && make.status < 0 && <p>Error...</p>}
            {!make || make.status <= 1 && <div className="lds-dual-ring"></div>}
        </div>
      </>
      }
    </SeoulLayout>
  )
}

export default MakeDetails
