import React, {useEffect, useState} from 'react'
import {Row, Col, Container, Form, Button} from 'react-bootstrap'
import {Link, Redirect} from 'react-router-dom'
import {useHistory} from 'react-router-dom'
import {getAccessToken, httpGet, httpPost, setAccessToken} from '@src/utils';
import * as C from '@src/commons';
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { setMe } from '@src/store/auth';
import { ReducerType } from '@src/store';
import { IUser } from '@src/shared/types';
import SeoulLayout from './seoul-layout';

const About = () => {
  return (
    <div>
      <SeoulLayout>
        <Col sm={{span: 6, offset: 3}} className=' justify-content-center'>
          <img src="/images/patreon1.png" className='img-thumbnail '/>
          <h1 className='p-2'>Welcome to PornSushi!</h1>
        <p style={{whiteSpace: 'pre-wrap', color: 'white', fontSize:"15px"}} className='p-2'>
          <span className='text-primary'>Pornsushi.ai</span> generates adult imagery with custom AI algorithms. Just choose your tags and click generate to get started!<br/><br/>
          For comments, suggestions, or questions, please visit our<span className='text-primary'> patreon and discord.</span><br/><br/>
          Any generations of content on this website that resemble real people are purely coincidental. All people generated are 18+ years of age. This AI mirrors biases and misconceptions that are present in its training data.<br/><br/>
          For business inquiries, account deletion or content takedown, please contact <a href="mailto:sushicatai@gmail.com">sushicatai@gmail.com</a><br/><br/><br/>
          <Link to="/terms">Terms of Service</Link><br/>
          <Link to="/privacy">Privacy Policy</Link><br/>
        </p>
        </Col>
      </SeoulLayout>
    </div>
  )
}

export default About
