import React from 'react'
import {Switch,Route} from 'react-router-dom'

// auth
import ConfirmMail from 'views/dashboard/auth/confirm-mail'
import LockScreen from 'views/dashboard/auth/lock-screen'
import Recoverpw from 'views/dashboard/auth/recoverpw'
import SignIn from '@src/views/auth/signin'
import SignUp from '@src/views/auth/signup'
import Index from '@src/views/auth/index'
import Make from '@src/views/auth/make'
import FeedPage from '@src/views/auth/feed'
import About from '@src/views/auth/about'
import Me from '@src/views/auth/me'
import GetPro from '@src/views/auth/getpro'
import GetProPatreon from '@src/views/auth/getpro-patreon'
import MakeDetails from '@src/views/auth/details'

// errors
import Error404 from 'views/dashboard/errors/error404'
import Error500 from 'views/dashboard/errors/error500'

//extrpages
import Maintenance from 'views/dashboard/extrapages/maintenance'
import ComingSoon from 'views/dashboard/extrapages/comingsoon'
import Terms from '@src/views/auth/terms'
import PrivacyPolicy from '@src/views/auth/privacy'
import * as C from '@src/commons';
import { GoogleOAuthProvider } from '@react-oauth/google'
import SearchPage from '@src/views/auth/search'
import TagsPage from '@src/views/auth/tags'
import MeSaves from '@src/views/auth/me-saves'
import ForgotPassword from '@src/views/auth/forgot-password'
import Chat from '@src/views/auth/chat'
import AdminChatTest from '@src/views/auth/admin-chat-test'

const SimpleRouter = () => {
    return (
        <GoogleOAuthProvider
            clientId={C.GOOGLE_CLIENT_ID}
        >
            <Switch>
                <Route path="/"  exact component={FeedPage} />
                <Route path="/make"  exact component={Make} />
                <Route path="/chat"  exact component={Chat} />
                <Route path="/make/:id"     component={MakeDetails} />
                <Route path="/search"  exact component={SearchPage} />
                <Route path="/tags"  exact component={TagsPage} />
                <Route path="/about"  exact component={About} />
                <Route path="/terms"  exact component={Terms} />
                <Route path="/privacy"  exact component={PrivacyPolicy} />
                <Route path="/signin"  exact component={SignIn} />
                <Route path="/signup"  exact component={SignUp} />
                <Route path="/forgotPassword"  exact component={ForgotPassword} />
                <Route path="/me"  exact component={Me} />
                <Route path="/me/saves"  exact component={MeSaves} />
                <Route path="/getpro"  exact component={GetPro} />
                <Route path="/getpro/patreon"  exact component={GetProPatreon} />

                {/* admin */}
                <Route path="/admin/chatTest"  exact component={AdminChatTest} />
                
                <Route path="/"                 component={Error404}/>  
                
                {/* error */}
                <Route  path="/errors/error500"                 component={Error500}/>

                {/* extra-pages */}
                <Route  path="/extra-pages/pages-maintenance"   component={Maintenance}/>
                <Route  path="/extra-pages/pages-comingsoon"    component={ComingSoon}/>
                
            </Switch>       
        </GoogleOAuthProvider>
    )
}

export default SimpleRouter
