import React from 'react'

//SimpleRouter 
import SimpleRouter from './simple-router'

export const SimpleLayout = () => {
    return (
        <>
          <div className="wrapper">
            <SimpleRouter />
          </div>
        </>
    )
}