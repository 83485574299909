import React, {useEffect, useRef, useState} from 'react'
import {Row, Col, Container, Form, Button, Modal} from 'react-bootstrap'
import {Link, Redirect} from 'react-router-dom'
import {useHistory} from 'react-router-dom'
import {getAccessToken, httpGet, httpPost, setAccessToken} from '@src/utils';
import * as C from '@src/commons';
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { setAgreeTerms, setMe } from '@src/store/auth';
import { ReducerType } from '@src/store';
import { IMake, IUser } from '@src/shared/types';
import SeoulLayout from './seoul-layout';

const FeedPage = () => {
  let history = useHistory()
  let [makes, setMakes] = useState<IMake []>([]);
  let [live, setLive] = useState(true);
  let [loading, setLoading] = useState(false);
  let timerIdRef = useRef(null as any);
  let agreeTerms = useSelector<ReducerType, boolean>(state => state.auth.agreeTerms);
  let dispatch = useDispatch()

  function fetch(order: string = 'desc', reset: boolean = true, showLoading: boolean = true) {
    
    var url = 'api/makes?a';

    //
    if(!reset) {
      let lastID = '';
      if(order == 'desc')
        lastID = makes.length > 0 && makes[makes.length - 1].hash_id || '';
      else
        lastID = makes.length > 0 && makes[0].hash_id || '';
      url += '&lastID=' + lastID;
    }

    //
    url += '&order=' + order;
    
    //
    if(showLoading)
      setLoading(true);
    httpGet(url).then((res) => {
      if(showLoading)
        setLoading(false);

      if(res.makes.length > 0)
        setMakes(res.makes);
    }).catch(() => setLoading(false));
  }

  function goPage(offset: number) {
    setLive(false);

    if(offset == +1)
      fetch('desc', false);
    else if(offset == -1)
      fetch('asc', false);
  }

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    clearInterval(timerIdRef.current);

    //
    if(live) {
      timerIdRef.current = setInterval(() => {
          fetch('desc', true, false);
      }, 3000);

      return () => clearInterval(timerIdRef.current);
    }
  }, [live]);


  return (
    <SeoulLayout>
      <div>
        <div style={{textAlign: 'center'}}>
            <Form.Group className="form-group mt-3">
              <Button variant="success" className="me-2 menu-button" onClick={() => setLive(!live)}>Live {live && 'OFF' || 'ON'}</Button>
              <Button variant="success" className="me-2 menu-button" onClick={() => goPage(-1)} disabled={loading}>Newer</Button>
              <Button variant="success" className="me-2 menu-button" onClick={() => goPage(1)} disabled={loading}>Older</Button>
            </Form.Group>
        </div>

        {agreeTerms && 
        <Row>
          {makes.map((x: IMake) => (
            <Col md={3} lg={2} xs={6} className="p-1" key={x.hash_id}>
              <Link to={"/make/" + x.hash_id}>
                <img src={x.image_url} width="100%"/>
              </Link>
            </Col>
          ))}
        </Row>
        }
        
        <div className='m-2' style={{textAlign: 'center'}}>
            <Form.Group className="form-group">
              <Button variant="success" className="me-2 menu-button" onClick={() => setLive(!live)}>Live {live && 'OFF' || 'ON'}</Button>
              <Button variant="success" className="me-2 menu-button" onClick={() => goPage(-1)} disabled={loading}>Newer</Button>
              <Button variant="success" className="me-2 menu-button" onClick={() => goPage(1)} disabled={loading}>Older</Button>
            </Form.Group>
        </div>

      </div>
      
      <Modal show={!agreeTerms} onHide={() => dispatch(setAgreeTerms(true))} keyboard={false} backdrop={'static'}>
          <Modal.Header>
          <Modal.Title>Welcome</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-black">
            Warning, this site is for adults only. It contains AI-generated adult imagery.<br/>
            <br/>
            By entering this website, I recognize that I am 18 years old or more. By using the site, you agree to our Terms of Service. Our privacy policy details how we collect and use your data. We use cookies for basic analytics and spam detection.<br/>
            <br/>
            Any generations of content on this website that resemble real people are purely coincidental.
          </Modal.Body>
          <Modal.Footer>
          <Button variant="danger" onClick={() => dispatch(setAgreeTerms(true))}>
              Accept
          </Button>
          <Button variant="secondary" onClick={() => window.location.href = 'https://google.com'}>
              Decline
          </Button>
          </Modal.Footer>
      </Modal>
    </SeoulLayout>
  )
}

export default FeedPage
