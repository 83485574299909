import React from 'react'


//router
import { Switch,Route } from 'react-router-dom'

//layoutpages
import { SimpleLayout } from './layouts/dashboard/simple'

export const MainRouter = () => {
    return (
        <>
            <Switch>
                <Route path="/"                       component={SimpleLayout}/>
            </Switch>
        </>
    )
}