
import React, { useEffect, useState } from 'react'
import {Row, Col, Container, Button} from 'react-bootstrap'
import Card from '@src/components/Card'
import {Link, useHistory, useLocation} from 'react-router-dom'
import { httpPost } from '@src/utils'
import { IApiResult } from '@src/shared/types'


const GetProPatreon = () => {

   let params = new URLSearchParams(window.location.search);
   let history = useHistory();
   let [randomNum, setRandomNum] = useState(Math.random());

   useEffect(() => {
      if(params.get('error'))
         return alert(params.get('error'));

      //
      httpPost('api/me/getpro', {
         type: 'patreon',
         code: params.get('code'),
      }).then((res: IApiResult) => {
         if(res.status >= 0) {
            history.push('/');
         } else {
            alert(res.message);
            history.push('/getpro');
         }
      })
   }, []);

   return (
      <div>
         <img id="1000407221_cpa_testing" src={`https://ads.trafficjunky.net/tj_ads_pt?a=1000407221&member_id=1005048871&cb=${randomNum}&cti=[TRANSACTION_UNIQ_ID]&ctv=[VALUE_OF_THE_TRANSACTION]&ctd=[TRANSACTION_DESCRIPTION]`} 
            width="1" height="1"/>
         <img src='https://ck.juicyads.com/ilikeitjuicy_px.php?c=g4u4w5i5f4f5l4l5e5y5u4w5d4a482a6x27454x294w4p284'/>
      </div>
   )
}

export default GetProPatreon