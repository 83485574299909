import React, {useEffect, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {getMakeTags, httpGet, pickItem} from '@src/utils'
import {ITagGroup, IUser} from "@shared/types"
import {TagButtons} from "@shared/blocks"
import {useSelector} from "react-redux"
import {ReducerType} from "@src/store"
import {useHistory} from "react-router-dom"
import {TagModal} from "@src/components/tag-modal"


interface Props {
  onChange?: (tags: string[]) => void
  showCopyTags?: boolean
}

const TagSelector = (props: Props) => {
  const history = useHistory()
  const [generator, setGenerator] = useState<ITagGroup[]>([])
  const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me)
  const [proModalOpen, setProModalOpen] = React.useState(false)
  const params = new URLSearchParams(window.location.search)
  const [proTag, setProTag] = useState('')

  let INITIAL_TAGS = ["woman",pickItem(['Model A', 'Model B', 'Model C'])]
  if (params.get('tags')) {
    INITIAL_TAGS = JSON.parse(window.atob(params.get('tags') || ''))
  }

  const [tags, setTags] = useState(INITIAL_TAGS)
  const openProModal = () => setProModalOpen(true)

  useEffect(() => {
    const storageTags = getMakeTags()
    if (!storageTags) {
      httpGet('api/tags')
        .then((res: any) => {
          setGenerator(res.tags)
        })
    } else {
      setGenerator(storageTags)
    }
  }, [])

  //
  useEffect(() => {
    if (props.onChange)
      props.onChange(tags)
  }, [tags])

  //
  function toggleTag(tag: string, proUsersOnly = false) {
    if (tags.includes(tag)) {
      setTags(tags.filter(x => x != tag))
    } else {
      if ((!me || !me.is_pro) && proUsersOnly) {
        setProTag(tag)
        openProModal()
        return false
      }
      setTags([...tags, tag])
    }
  }

  function clearTags() {
    setTags([])
  }

  function copyTags() {
    const tagsEncoded = window.btoa(JSON.stringify(tags))
    const url = window.location.protocol + '//' + window.location.host + '/make?tags=' + encodeURIComponent(tagsEncoded)

    window.navigator.clipboard.writeText(url).then(() => {
      alert("Copied to clipboard!")
    })
  }

  //
  // function handleSelectGenerator(e: any) {
  //   setGeneratorID(parseInt(e.target.value));
  //   setGenerator(Generators.find(x => x.id == parseInt(e.target.value)));
  // }



  return (
    <div>
      <Form style={{width: '200px'}}>
        {/* <Form.Group className="form-group">
            <Form.Label htmlFor="generator">Generator</Form.Label>
            <select className="form-select" id="generator" onChange={handleSelectGenerator} value={generatorID}>
              {Generators.map(x => <option value={x.id} key={x.id}>{x.name}</option>)}
            </select>
        </Form.Group> */}
        <Form.Group className="form-group">
          <Button variant="success" className="me-2" onClick={() => clearTags()}>Clear tags</Button>
          {props.showCopyTags && <Button variant="light" onClick={() => copyTags()}>Copy tags</Button>}
        </Form.Group>
      </Form>

      <Form style={{marginBottom: '6rem'}}>
        {generator?.map((x: ITagGroup) => (
          <Form.Group className="mb-4" key={x.name}>
            <p className="mb-0 fw-bold">{x.name}</p>
            {TagButtons(x, tags, toggleTag)}
          </Form.Group>
        ))}
      </Form>
      <TagModal
        tagName={proTag}
        isOpen={proModalOpen}
        handleCloseHandler={()=>{setProModalOpen(false)}}
        handleLinkHandler={()=>{history.push('/getpro')}}
      />
    </div>
  )
}

export default TagSelector
