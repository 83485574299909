import { combineReducers, configureStore } from '@reduxjs/toolkit';
import settingReducer from './setting/reducers';
import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '@src/shared/types';

export interface AuthState {
  accessToken?: string
  me?: IUser
  agreeTerms: boolean
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    agreeTerms: false
  } as AuthState,
  reducers: {
    setAccessToken: (state: AuthState, action) => {
      state.accessToken = action.payload;
      return state;
    },
    setMe: (state: AuthState, action) => {
      state.me = action.payload;
      return state;
    },
    setAgreeTerms: (state: AuthState, action) => {
      state.agreeTerms = action.payload;
      return state;
    }
  }
});

export const { setAccessToken, setMe, setAgreeTerms } = authSlice.actions;

export default authSlice.reducer;