import React, {useState} from 'react'
import {Row, Col, Container, Form, Button, Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useHistory} from 'react-router-dom'
import styled from "@emotion/styled";
import Swal from 'sweetalert2'

//swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Autoplay} from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';

//img
import townImage from 'assets/images/login/town.png'
import {httpPost, setAccessToken} from "@src/utils";
import SeoulLayout from './seoul-layout';

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

type FormDataType = {
  name: string;
  email: string;
  password: string;
  agreement: boolean;
  payload: string;
}

const SignUp = () => {
  let params = new URLSearchParams(window.location.search);
  let payload = params.get('payload');
  let history = useHistory();
  let [isLoading, setLoading] = useState(false);
  let [formData, setFormData] = useState<FormDataType>({
    name: '',
    email: '',
    password: '',
    agreement: false,
    payload: payload || '',
  });
  let [step, setStep] = useState(!!payload ? 2 : 1);
  let [randomNum, setRandomNum] = useState(Math.random());

  //
  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) return;
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);
    await signup();
    setLoading(false);
  };

  async function signup() {
    const data = await httpPost(`api/me/signup`, formData);
    if (data.status >= 0) {

      //
      setAccessToken(data.token);

      //
      Swal.fire({
        html: `Sign up is complete.`,
        icon: 'success',
        confirmButtonText: 'OK',
      }).then();
      history.push('/');
    }
  }

  return (
    <SeoulLayout>
      <div className="sign-in-from" style={{margin: '0 auto', width: '400px'}}>
            <h1 className="mb-0">Sign up</h1>

            {step == 1 &&
            
            <Form
              className="mt-4"
              onSubmit={handleSubmit}
            >
              <Form.Group className="form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  className="mb-0"
                  placeholder=""
                  required
                  defaultValue={formData.email}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      email: e.target.value,
                    });
                  }}
                />
              </Form.Group>
              <div className="d-inline-block w-100">
                <Button
                  type="submit"
                  className="btn-primary float-end"
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Send verification code'}
                </Button>
              </div>
              <div className="sign-info">
                <span className="dark-color d-inline-block line-height-2">
                  Already have account? <Link to="/signin">Sign in</Link>
                </span>
              </div>
            </Form>
            }
            
            {step == 2 && 
            <Form
              className="mt-4"
              onSubmit={handleSubmit}
            >
              <Form.Group className="form-group">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-0"
                  placeholder=""
                  required
                  defaultValue={formData.name}
                  minLength={2}
                  maxLength={20}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      name: e.target.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  className="mb-0"
                  placeholder=""
                  required
                  minLength={8}
                  maxLength={30}
                  defaultValue={formData.password}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      password: e.target.value,
                    });
                  }}
                />
              </Form.Group>
              <div className="d-inline-block w-100">
                <Form.Check className="d-inline-block mt-2 pt-1">
                  <Form.Check.Input
                    type="checkbox"
                    className="agreement-checkbox"
                    checked={formData.agreement}
                    required
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        agreement: e.target.checked,
                      });
                    }}
                  />
                  <Form.Check.Label>
                    I agree to <a href="/terms" target="_blank">The Terms of Service</a> and <a href="/privacy" target="_blank">Privacy Policy
                    </a>.
                  </Form.Check.Label>
                </Form.Check>
                <Button
                  type="submit"
                  className="btn-primary float-end"
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Sign up'}
                </Button>
                <img id="1000407211_cpa_testing" src={`https://ads.trafficjunky.net/tj_ads_pt?a=1000407211&member_id=1005048871&cb=${randomNum}&cti=[TRANSACTION_UNIQ_ID]&ctv=[VALUE_OF_THE_TRANSACTION]&ctd=[TRANSACTION_DESCRIPTION]`}
                  width="1" height="1"/>
                <img src='https://ck.juicyads.com/ilikeitjuicy_px.php?c=j4i4l5t5s4u514z506y5h4m5x4u2u294y29466r2'/>
              </div>
              <div className="sign-info">
                <span className="dark-color d-inline-block line-height-2">
                  Already have account? <Link to="/signin">Sign in</Link>
                </span>
              </div>
            </Form>
            }
          </div>
    </SeoulLayout>
  )
}

export const Styled = styled.div`
  .title {
    font-size: 40px;
    font-weight: 500;
    color: white;
  }

  .agreement-checkbox {
    margin-top: -1px;
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
`;

export default SignUp
