import { combineReducers, configureStore } from '@reduxjs/toolkit';
import settingReducer from './setting/reducers';
import authReducer from './auth';

//
const reducer = combineReducers({
  setting: settingReducer,
  auth: authReducer,
});

//
export type ReducerType = ReturnType<typeof reducer>;

//
export const store = configureStore({
  reducer: reducer,
});

export type AppDispatch = typeof store.dispatch