import React, {useState} from 'react'
import {Row, Col, Container, Form, Button, Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useHistory} from 'react-router-dom'
import styled from "@emotion/styled";
import Swal from 'sweetalert2'

//swiper
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Autoplay} from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';

//img
import townImage from 'assets/images/login/town.png'
import {httpPost, setAccessToken} from "@src/utils";
import SeoulLayout from './seoul-layout';

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

type FormDataType = {
  email: string;
  password: string;
  payload: string;
}

const ForgotPassword = () => {
  let params = new URLSearchParams(window.location.search);
  let payload = params.get('payload');
  let history = useHistory();
  let [isLoading, setLoading] = useState(false);
  let [formData, setFormData] = useState<FormDataType>({
    email: '',
    password: '',
    payload: payload || '',
  });
  let [step, setStep] = useState(!!payload ? 2 : 1);

  //
  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) return;
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);
    await confirm();
    setLoading(false);
  };

  async function confirm() {
    const data = await httpPost(`api/me/forgotPassword`, formData);
    if (data.status >= 0) {

      //
      setAccessToken(data.token);

      //
      if(formData.email) {
        Swal.fire({
          html: `Sent verification code to ${formData.email}`,
          icon: 'success',
          confirmButtonText: 'OK',
        }).then();
        history.push('/signin');
      } else {
        Swal.fire({
          html: `Password changed!`,
          icon: 'success',
          confirmButtonText: 'OK',
        }).then();
        history.push('/me');
      }
    }
  }

  return (
    <SeoulLayout>
      <div className="sign-in-from" style={{margin: '0 auto', width: '500px'}}>
            <h1 className="mb-0">Forgot password</h1>

            {step == 1 &&
            
            <Form
              className="mt-4"
              onSubmit={handleSubmit}
            >
              <Form.Group className="form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  className="mb-0"
                  placeholder=""
                  required
                  defaultValue={formData.email}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      email: e.target.value,
                    });
                  }}
                />
              </Form.Group>
              <div className="d-inline-block w-100">
                <Button
                  type="submit"
                  className="btn-primary float-end"
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Send verification code'}
                </Button>
              </div>
              <div className="sign-info">
                <span className="dark-color d-inline-block line-height-2">
                  Already know password? <Link to="/signin">Sign in</Link>
                </span>
              </div>
            </Form>
            }
            
            {step == 2 && 
            <Form
              className="mt-4"
              onSubmit={handleSubmit}
            >
              <Form.Group className="form-group">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  className="mb-0"
                  placeholder=""
                  required
                  minLength={8}
                  maxLength={30}
                  defaultValue={formData.password}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      password: e.target.value,
                    });
                  }}
                />
              </Form.Group>
              <div className="d-inline-block w-100">
                <Button
                  type="submit"
                  className="btn-primary float-end"
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Change password'}
                </Button>
              </div>
              <div className="sign-info">
                <span className="dark-color d-inline-block line-height-2">
                  Already know password? <Link to="/signin">Sign in</Link>
                </span>
              </div>
            </Form>
            }
          </div>
    </SeoulLayout>
  )
}

export const Styled = styled.div`
  .title {
    font-size: 40px;
    font-weight: 500;
    color: white;
  }

  .agreement-checkbox {
    margin-top: -1px;
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
`;

export default ForgotPassword
