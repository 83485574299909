import React, {useEffect, useState} from 'react'
import {Row} from 'react-bootstrap'
import SeoulLayout from './seoul-layout'
import {ChatWidget} from "@src/views/auth/chat-widget"
import {httpGet} from "@src/utils"
import {IMake} from "@shared/types"


const Chat = () => {
  const [make, setMake] = useState<IMake>()

  useEffect(() => {
    httpGet('api/makes?limit=1&random=1').then((res) => {
      setMake(res.makes[0])
    })
  }, [])

  return make ? (
    <div style={{height: '100%'}}>
      <SeoulLayout style={{height: '100%'}}>
        <Row className="chat-container">
          <ChatWidget makeHashID={make?.hash_id ?? ""} backgroundImageUrl={make?.image_url} fullScreen={true} />
        </Row>
      </SeoulLayout>
    </div>
  ) : null
}

export default Chat
