import React, {useEffect, useState} from 'react'
import {Row, Col, Container, Form, Button} from 'react-bootstrap'
import {Link, Redirect} from 'react-router-dom'
import {useHistory} from 'react-router-dom'
import {getAccessToken, httpGet, httpPost, setAccessToken} from '@src/utils';
import * as C from '@src/commons';
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { setMe } from '@src/store/auth';
import { ReducerType } from '@src/store';
import { IUser } from '@src/shared/types';
import SeoulLayout from './seoul-layout';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import TagSelector from './tag-selector';


const Make = () => {
  let history = useHistory()
  const [loading, setLoading] = useState(false);
  const [generatorID, setGeneratorID] = useState(1);
  const { executeRecaptcha }: any = useGoogleReCaptcha();
  const [tags, setTags] = useState([] as string []);
  const me = useSelector<ReducerType, IUser | undefined>(state => state.auth.me);

  //
  async function generate(visible: number) {
    setLoading(true);

    if(visible == 0) {
      if(!me || !me.is_pro)
        return alert('You need to subscribe PRO mode.');
    }

    //
    const recaptcha = await executeRecaptcha('make');
    httpPost('api/makes/generate', {
      generator_id: generatorID,
      tags: tags,
      visible: visible,
      parent_id: 0,
      recaptcha: recaptcha,
    }).then((res) => {
      setLoading(false);

      //
      if(res.status >= 0) {
        history.push('/make/' + res.make.hash_id);
      }
    });
  }

  return (
    <div>
      <SeoulLayout>
        <TagSelector onChange={(t) => setTags(t)} showCopyTags={true}/>
      </SeoulLayout>

      <div style={{position: 'fixed', width: '100%', bottom: '20px', textAlign: 'center'}}>
        <Button className="me-2" variant="danger" size={"lg"} style={{width: '180px'}} disabled={loading} onClick={() => generate(1)}>Generate</Button>
        <Button variant="secondary" size={"lg"} style={{width: '180px'}} disabled={loading} onClick={() => generate(0)}>⭐ Generate (private)</Button>
      </div>
    </div>
  )
}

export default Make
